





















































































import Multiselect from 'vue-multiselect';
import Vue, { PropType } from 'vue';
import { InputOption } from '@/model/input-option';

export default Vue.extend({
  components: {
    Multiselect
  },
  props: {
    label: {
      type: String,
      required: true
    },
    labelTooltip: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: true
    },
    trackBy: {
      type: String,
      required: true
    },
    customLabel: {
      type: Function,
      required: true
    },
    options: {
      type: Array as PropType<InputOption[]>,
      required: true
    },
    defaultValue: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      value: this.defaultValue
    };
  },
  watch: {
    defaultValue(defaultValue) {
      this.value = defaultValue;
    }
  },
  methods: {
    onChange(value) {
      this.$emit('onValueChange', value);
    }
  }
});
